var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-6 ml-8 mr-6",attrs:{"id":"iiotDeployLogList","fill-height":""}},[_c('the-nerve-table',{attrs:{"id":'iiotDeployLogListTable',"columns":_vm.columns,"params":_vm.params,"empty-state":_vm.$t('deployLog.list.emptyList'),"is-action-menu-enabled":false,"is-row-clickable":_vm.canAccess('UI_DEPLOY:LOG_VIEW'),"have-access":_vm.canAccess('UI_DEPLOY:LOG_VIEW') || _vm.canAccess('UI_SUBNAV_DEPLOY_LOG:VIEW'),"store-module":"deploy-log"},on:{"params-changed":_vm.updateParams,"row-clicked":params => _vm.editLog(params.item)},scopedSlots:_vm._u([{key:"additional-actions",fn:function(){return [_c('v-row',[_c('v-col',{staticClass:"pt-2 d-flex justify-center align-center",attrs:{"cols":"5","lg":"3","md":"4","sm":"4"}},[_c('v-select',{ref:"select",staticClass:"type-select",attrs:{"id":"iiotDeployLogListDeploymentSelect","items":[
              { value: 'ALL', text: _vm.$t('deployLog.list.all') },
              { value: 'false', text: _vm.$t('deployLog.list.deploys') },
              { value: 'true', text: _vm.$t('deployLog.list.dryRuns') },
            ],"item-text":_vm.deploymentTypes.text,"item-value":_vm.deploymentTypes.value,"placeholder":_vm.$t('deployLog.list.deploymentType'),"attach":"","prepend-icon":"mdi-filter-outline"},on:{"change":function($event){return _vm.filterBy()}},model:{value:(_vm.params.selectedDeploymentType),callback:function ($$v) {_vm.$set(_vm.params, "selectedDeploymentType", $$v)},expression:"params.selectedDeploymentType"}})],1),_c('v-col',{staticClass:"pt-2 d-flex justify-center align-center",attrs:{"cols":"5","lg":"3","md":"4","sm":"4"}},[_c('v-select',{ref:"select",staticClass:"type-select",attrs:{"id":"iiotDeployLogListWorkloadSelect","items":[
              { value: 'ALL', text: _vm.$t('deployLog.list.all') },
              { value: 'vm', text: _vm.$t('deployLog.list.vm') },
              { value: 'docker', text: _vm.$t('deployLog.list.docker') },
              { value: 'codesys', text: _vm.$t('deployLog.list.codesys') },
              { value: 'docker-compose', text: _vm.$t('deployLog.list.docker-compose') },
            ],"item-text":_vm.workloadTypes.text,"item-value":_vm.workloadTypes.value,"placeholder":_vm.$t('workloads.list.workloadType'),"attach":""},on:{"change":function($event){return _vm.filterBy()}},model:{value:(_vm.params.selectedWorkloadType),callback:function ($$v) {_vm.$set(_vm.params, "selectedWorkloadType", $$v)},expression:"params.selectedWorkloadType"}})],1)],1),_c('div')]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }